.termBody {
    background-color: #fff;
    color: #000;
    text-align: left;
    font-family: 'Nanum Gothic', 'Malgun Gothic', '맑은 고딕', dotum, '돋움', sans-serif;
    padding: 2px; 
    line-height: 18px;
    font-size: 12px;
}

.termBody h1 {
    color: #363636;
    letter-spacing: -1px;
   line-height:110%;
    border-bottom: none;
}

.termBody h2 {
    color: #363636;
    font-size: 16px;
    letter-spacing: -1px;
    border-bottom: none;
}

.termBody h3 {
    color: #363636;
    font-size: 16px;
    letter-spacing: -1px;
}

.termBody h4 {
    color: #363636;
    font-size: 14px;
    letter-spacing: -1px;
    font-weight: bold;
}

.termBody p, .termBody ul {
    font-size: 14px;
    color: #363636;
    text-align: justify;
    letter-spacing: -1px;
    line-height: 1.7;
}

.termBody ol {
    list-style: none;
}