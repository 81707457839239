/* Position and sizing of burger button */
.bm-burger-button {
    border-width: 1px;
    border-color: #373a47;
  position: fixed;
  width: 30px;
  height: 25px;
  right : 15px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  left : 0;
  top : 0;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left : 0;
  top : 0;
}

.main-link {
    margin-bottom: 1rem;
    font-size: 1em;
}

.main-link a{
    color: #FFFFFF;
    text-decoration: none;
}

.main-link a:hover{
    color: #4fa670;
}

.main-link-second {
  margin-bottom: 0.5rem;
  margin-left: 2rem;
}


.main-link-second a{
  color: #FFFFFF;
  text-decoration: none;
  
}

.main-link-second a:hover{
  color: #4fa670;
}

.main-header {
  color: #FFFFFF;
  font-size: 1em;
  cursor: pointer;
  background-color: transparent;
  border-width: 0;
  padding: 0;
}

.main-header:hover {
  color: #4fa670;
}

@media screen and (min-width: 768px) {
    .bm-burger-button {
      position: relative;
      left : 0px;
      top: 0px;
    }
}