body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-main {
  background-color: #91CCA8
}

.bg-gainsboro{
  background-color: gainsboro;
}

.bg-floralwhite{
  background-color: floralwhite;
}

.btn-main, .btn-main:hover, .btn-main:active, .btn-main:visited {
  background-color: #91CCA8 !important;
}

.text-violet {
  color: blueviolet;
}

.text-main {
  color: #91CCA8;
}

.text-blue {
  color: blue;
}

.cursor-pointer{
  cursor: pointer;
}

.btn-height{
  height: 2.4em;
}

.btn-height-two{
  height: 2.65em;
}


